<template>
  <div class="settings">
    <app-redirect/>
  </div>
</template>

<script>

import appRedirect from "@/pages/components/appRedirect";
export default {
  name: "src-pages-body-settings-mainpage-v1_mobile",
  components:{appRedirect},
  data() {
    return {
    }
  },
}

</script>
